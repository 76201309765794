<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        :append-icon="icons.mdiMagnify"
        label="Recherche"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="filterStudents"
      items-per-page="25"
    >
      <template #[`item.nom`]="{item}">
        <div
          class="d-flex flex-column"
          @click="goToStud(item)"
        >
          <span
            class="d-block font-weight-semibold text--primary"
            @click="$router.push({name: 'student-single', params :{id: item.id}})"
          > {{ item.prenom }} {{ item.nom }}</span>
        </div>
      </template>
      <template #[`tuteurs[0].nom`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text--primary text-truncate"> {{ item.tuteurs.length > 0 ? item.tuteurs[0].nom : '' }}</span>
        </div>
      </template>
      <template #[`item.formation.nom`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block text--primary"> {{ item.formation.nom.substring(0, 15) }}</span>
        </div>
      </template>
      <template #[`item.tutorat_time`]="{item}">
        <div class="d-flex flex-column">
          <span class="d-block text--primary text-truncate"> {{ new Date(item.tutorat_time) | date('dd MMMM HH:mm') }}</span>
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <DateTimePickerVue
          :button-text="icons.mdiPencil"
          color="primary"
          :icon="true"
          @newDate="editTutoratTime($event, item)"
        ></DateTimePickerVue>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiMagnify, mdiPencil, mdiDelete } from '@mdi/js'
import axios from 'axios'
import DateTimePickerVue from '@/components/DateTimePicker.vue'

export default {
  components: {
    DateTimePickerVue,
  },
  setup() {
    return {
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data() {
    return {
      studId: '',
      dialogEdit: false,
      search: '',
      loading: false,
      studVisio: [
        {
          nom: '',
          prenom: '',
          tuteurs: [
            {
              nom: '',
            },
          ],
          formation: {
            nom: '',
          },
          tutorat_time: new Date(),
        },
      ],
      error: null,
      dialog: false,
      tutoratDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      tutoratTime: '12:30',
      headers: [
        {
          text: 'Elève',
          align: 'start',
          value: 'nom',
        },
        {
          text: 'Tuteur',
          value: 'tuteurs[0].nom',
        },
        {
          text: 'Formation',
          value: 'formation.nom',
        },
        {
          text: 'Date du tutorat',
          value: 'tutorat_time',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    filterStudents() {
      const results = this.studVisio.filter(e => e.nom.toLowerCase().includes(this.search.toLowerCase()) || e.prenom.toLowerCase().includes(this.search.toLowerCase()))

      return results
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      axios.get(`${process.env.VUE_APP_API_URL}/students?visio=true`).then(
        res => {
          this.loading = false
          this.studVisio = res.data
        },
      ).catch(err => console.log(err))
    },
    goToStud(e) {
      this.$router.push({ name: 'student-single', params: { id: e.id } })
    },
    editItem(item) {
      this.dialogEdit = true
      this.studId = item.id
      if (item.tutoratTime) {
        this.tutoratDate = item.tutoratTime
        this.tutoratTime = new Date(item.tutoratTime).getTime()
      }
    },
    editTutoratTime(e, item) {
      const newTut = new Date(e)
      axios.put(`${process.env.VUE_APP_API_URL}/students/${item.id}`, { tutorat_time: newTut }).then(res => {
        this.$store.commit('snackMe', { value: true, color: 'success', text: 'Date de tutorat mise à jour' })
        const studUpdate = this.studVisio.find(a => a.id === item.id)
        studUpdate.tutorat_time = res.data.tutorat_time
        this.dialogEdit = false
      }).catch(err => {
        console.log(err)
        this.$store.commit('snackMe', { value: true, color: 'error', text: 'Erreur lors de la mise à jour du tutorat' })
      })
    },
  },
}
</script>

<style>

</style>
